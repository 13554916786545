import client from "@/api";
import store from "@/store";
import {
  _rotaListarSac,
  _rotaListarSacStatus,
  _rotaListarSacAssuntos,
  _rotaAtualizarStatusOcorrencia,
} from "@/api/_caminhosApi";
//
export function sacListarTodos(page, filtros) {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("sac/SET_TABELA", "");
  let data = {
    status: null,
    assunto: null,
    usuarioNome: filtros.usuarioNome,
    dataInicial: filtros.dataInicial,
    dataFinal: filtros.dataFinal,
    email: filtros.email,
  };

  if (Object.keys(filtros).length > 0) {
    if (filtros.status) {
      if (filtros.status.id !== null) {
        data.status = filtros.status.id;
      } else {
        data.status = null;
      }
    }
    if (filtros.assunto) {
      if (filtros.assunto.id !== null) {
        data.assunto = filtros.assunto.id;
      } else {
        data.assunto = null;
      }
    }
  } else {
    data = {};
  }

  client
    .post(`${_rotaListarSac()}?pagina=${page}`, data)
    .then((resp) => {
      store.commit("sac/SET_TABELA", resp.data.lista);
      store.commit("sac/SET_DATA_PAGINACAO", resp.data.paginacao);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function sacListarStatus() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("sac/SET_STATUS_ITENS", "");
  client
    .get(`${_rotaListarSacStatus()}`)
    .then((resp) => {
      let statuses = [{ descricao: "Todos", id: null }, ...resp.data];
      // let statuses = resp.data;
      store.commit("sac/SET_STATUS_ITENS", statuses);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}

export function sacListarAssuntos() {
  store.commit("interacoes/SET_LOADING", true);
  store.commit("sac/SET_ASSUNTOS_ITENS", "");
  client
    .get(`${_rotaListarSacAssuntos()}`)
    .then((resp) => {
      let assuntos = [{ descricao: "Todos", id: null }, ...resp.data];
      store.commit("sac/SET_ASSUNTOS_ITENS", assuntos);
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
export function atualizarStatusOcorrencia(data) {
  store.commit("interacoes/SET_LOADING", true);
  client
    .patch(_rotaAtualizarStatusOcorrencia(), data)
    .then(() => {
      store.commit("interacoes/SET_ALERTA", {
        ativado: true,
        mensagem: "Ocorrência atualizada com sucesso!",
        tipo: "sucesso",
      });
    })
    .catch((erro) => {
      console.log(erro);
    })
    .finally(() => {
      store.commit("interacoes/SET_LOADING", false);
    });
}
