<template>
  <div class="default-tabela fale-conosco-tabela">
    <div class="tabela-titulo">
      <h1>{{ camposInput.titulo }}</h1>
      <DownloadButton
        area="relatorio_de_ocorrencias"
        :list="planilhaParaDownload"
        :title="'Extrair lista'"
      />
    </div>

    <div class="tabela-filtros default-formulario">
      <div v-if="!this.semStatus" class="formulario-item">
        <label class="formulario-item-label">Status:</label>
        <multiselect
          v-model="form.status"
          :options="statusItem"
          label="descricao"
          track-by="id"
          :searchable="false"
          :allow-empty="false"
          placeholder="Selecione"
          select-label="Filtrar"
          deselect-label="Selecionado"
          selected-label="Selecionado"
          @close="fecharSelect('status')"
        />
      </div>
      <div v-if="!this.semAssuntos" class="formulario-item">
        <label class="formulario-item-label">Assunto:</label>
        <multiselect
          v-model="form.assunto"
          :options="assuntosItem"
          label="descricao"
          track-by="id"
          :searchable="false"
          :allow-empty="false"
          placeholder="Selecione"
          select-label="Filtrar"
          deselect-label="Selecionado"
          selected-label="Selecionado"
          @close="fecharSelect('assunto')"
        />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">Nome:</label>
        <input type="text" v-model="form.usuarioNome" placeholder="Nome" />
      </div>
      <div class="formulario-item">
        <label class="formulario-item-label">E-mail:</label>
        <input
          type="text"
          v-model="form.email"
          placeholder="email@exemplo.com"
        />
      </div>
      <div class="formulario-item">
        <div class="container-input container-datepicker">
          <label class="formulario-item-label">Data de Início:</label>
          <datepicker v-model="form.dataInicial" :language="ptBR"></datepicker>
        </div>
      </div>
      <div class="formulario-item">
        <div class="container-input container-datepicker">
          <label class="formulario-item-label">Data final:</label>
          <datepicker v-model="form.dataFinal" :language="ptBR"></datepicker>
        </div>
      </div>
      <div class="btns-formulario">
        <button class="btn btn-enviar" @click="filtrar(form)">Filtrar</button>
        <button class="btn" @click="limparFiltros">Limpar</button>
      </div>
    </div>

    <transition name="fade-left" mode="out-in">
      <div v-if="semDados" class="tabela-sem-dados" key="Vazio">
        <p>Sem dados no momento</p>
      </div>
      <div v-else class="tabela-informacoes" key="Lista">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-nome"><p>Nome</p></div>
          <div class="tabela-linha-email"><p>E-mail</p></div>
          <div class="tabela-linha-cadastro"><p>Data de envio</p></div>
          <div class="tabela-linha-cadastro"><p>Status</p></div>

          <div class="tabela-linha-btns"></div>
        </div>
        <div
          class="tabela-linha tabela-linha-informacoes"
          v-for="itemTabela in dadosTabela"
          :key="itemTabela.id"
        >
          <div class="tabela-linha-nome">
            <p>{{ itemTabela.sacOcorrencia.nome }}</p>
          </div>
          <div class="tabela-linha-email">
            <p>{{ itemTabela.sacOcorrencia.email }}</p>
          </div>
          <div class="tabela-linha-cadastro">
            <p>{{ itemTabela.dataCadastro | filterData }}</p>
          </div>
          <div class="tabela-linha-cadastro">
            <p>{{ itemTabela.sacOcorrencia.sacStatus.descricao }}</p>
          </div>

          <div class="tabela-linha-btns">
            <div
              class="tabela-linha-btns-editar"
              @click="verItem(itemTabela.id)"
            >
              <img
                src="~@/assets/images/icones/see.svg"
                alt="Detalhes"
                title="Detalhes"
              />
            </div>
          </div>
        </div>
      </div>
    </transition>
    <!-- .tabela-paginacao -->
    <div class="tabela-paginacao paginacao" v-if="!semDados">
      <div>
        <p>Total</p>
      </div>
      <div>
        {{ paginacao.totalPaginas }}
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina >= 2"
        @click="nudaPagina(1)"
      >
        <p>1</p>
      </div>
      <div>
        <div class="button" v-if="pageNumber > 1" @click="prevPage()">
          <img
            src="~@/assets/images/icones/next.svg"
            alt="<"
            title="Anterior"
            style=" transform: rotate(180deg);"
          />
        </div>
      </div>
      <div
        v-if="paginacao.numeroPagina > 1"
        @click="nudaPagina(paginacao.numeroPagina - 1)"
      >
        <p>{{ paginacao.numeroPagina - 1 }}</p>
      </div>
      <div class="pagina-atual" v-if="paginacao.numeroPagina >= 1">
        {{ paginacao.numeroPagina }}
      </div>
      <div
        v-if="
          paginacao.totalPaginas >= 3 &&
            paginacao.numeroPagina < paginacao.totalPaginas
        "
        @click="nudaPagina(paginacao.numeroPagina + 1)"
      >
        <p>{{ paginacao.numeroPagina + 1 }}</p>
      </div>
      <div>
        <div
          class="button"
          v-if="dadosTabela.length === 10"
          @click="nextPage()"
        >
          <img src="~@/assets/images/icones/next.svg" alt=">" title="Próximo" />
        </div>
      </div>
      <div
        class="paginacao-btn"
        v-if="paginacao.numeroPagina < paginacao.totalPaginas"
        @click="nudaPagina(paginacao.totalPaginas)"
      >
        <p>{{ paginacao.totalPaginas }}</p>
      </div>
    </div>
    <!-- <Paginacao :paginacao="paginacao" pagina="faleConosco"  v-if="!semDados"/> -->
    <!-- .tabela-paginacao -->
  </div>
</template>

<script>
import AwesomeMask from "awesome-mask";
import { sacListarTodos, sacListarStatus, sacListarAssuntos } from "@/api/sac";
import { _imagemMobile, _imagemDesktop } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterData, stringToDatetimeZone } from "@/utils/date";
import DownloadButton from "@/components/download-button/DownloadButton.vue";
import Multiselect from "vue-multiselect";
import Datepicker from "vuejs-datepicker";
import { ptBR } from "vuejs-datepicker/dist/locale";
import moment from "moment";
// import Paginacao from "@/components/paginacao/Paginacao.vue";

let filtrosBase = {
  status: null,
  assunto: null,
  usuarioNome: null,
  dataInicial: null,
  dataFinal: null,
  email: null
};
export default {
  directives: {
    mask: AwesomeMask
  },
  data() {
    return {
      ptBR: ptBR,
      planilhaParaDownload: [],
      tituloDownload: "",
      pageNumber: 1,
      semDados: true,
      semStatus: true,
      semAssuntos: true,
      form: filtrosBase,
      filtros: {},
      calendario: {
        inicio: "",
        final: ""
      },
      calendarioTexto: {
        placeholder: "Selecione o período",
        fromText: "De",
        toText: "a",
        resetText: "Limpar",
        confirmText: "Confirmar",
        weekList: ["Dom", "Seg", "Ter", "Qua", "Qui", "Sex", "Sab"],
        monthList: [
          "Janeiro",
          "Fevereiro",
          "Março",
          "Abril",
          "Maio",
          "Junho",
          "Julho",
          "Agosto",
          "Setembro",
          "Outubro",
          "Novembro",
          "Dezembro"
        ]
      }
    };
  },
  components: {
    Multiselect,
    DownloadButton,
    Datepicker
    // Paginacao
  },
  computed: {
    camposInput() {
      return this.$store.state.sac.componenteAtivado;
    },
    dadosTabela() {
      return this.$store.state.sac.tabela;
    },
    statusItem() {
      return this.$store.state.sac.statuses;
    },
    assuntosItem() {
      return this.$store.state.sac.assuntos;
    },
    paginacao() {
      return this.$store.state.cupom.paginacao;
    }
  },
  methods: {
    _imagemMobile,
    _imagemDesktop,
    numberToReal,
    sacListarTodos,
    montarDownload(data) {
      this.tituloDownload = ["Relatorio de Ocorrências"];
      if (data && Object.keys(data).length) {
        let newData = data.map(v => ({
          ID: v.id,
          Nome: v.sacOcorrencia.nome,
          "E-mail": v.sacOcorrencia.email,
          Mensagem: v.mensagem,
          "Data de envio": moment(v.sacOcorrencia.dataCadastro).format(
            "DD/MM/YYYY HH:mm"
          ),
          Status: v.sacOcorrencia.sacStatus.descricao
        }));
        this.planilhaParaDownload = newData;
      }
    },

    prevPage() {
      this.pageNumber = this.pageNumber - 1;
      this.verificaComponente();
    },
    nextPage() {
      this.pageNumber = this.pageNumber + 1;
      this.verificaComponente();
    },
    nudaPagina(id) {
      this.pageNumber = id;
    },
    buscaStatus() {
      return sacListarStatus();
    },
    buscaAssuntos() {
      return sacListarAssuntos();
    },
    novoEnvio() {
      this.$store.commit("sac/SET_TIPO_FORMULARIO", "Novo");
      this.$store.commit("sac/SET_SAC_TELA", "Formulario");
    },
    editarItem(id) {
      this.$store.commit("sac/SET_TIPO_FORMULARIO", "Editar");
      this.$store.commit("sac/SET_SAC_TELA", "Formulario");
      this.$store.commit("sac/SET_ID_FORMULARIO_EDITAR", id);
    },
    verItem(id) {
      this.$store.commit("sac/SET_TIPO_FORMULARIO", "Detalhes");
      this.$store.commit("sac/SET_SAC_TELA", "Detalhes");
      this.$store.commit("sac/SET_ID_FORMULARIO_EDITAR", id);
    },
    fecharSelect(item) {
      if (!this.form[item] || this.form[item].length < 1) {
        this.form[item] = null;
      }
      this.pageNumber = 1;
    },
    filtrar() {
      this.pageNumber = 1;
      this.filtros = this.form;
      this.sacListarTodos(this.pageNumber, this.filtros);
    },
    limparFiltros() {
      this.form = {
        status: null,
        assunto: null,
        usuarioNome: null,
        dataInicial: null,
        dataFinal: null,
        email: null
      };
      this.filtros = {};
      this.resetCalendario();
      this.sacListarTodos(this.pageNumber, this.filtros);
    },
    atualizaCalendario(data) {
      this.form.dataInicial = stringToDatetimeZone(data.start);
      this.form.dataFinal = stringToDatetimeZone(data.end);
    },
    resetCalendario() {
      this.form.dataInicial = "";
      this.form.dataFinal = "";
    }
  },
  filters: {
    filterData
  },
  mounted() {
    this.pageNumber = 1;
    this.buscaStatus();
    this.buscaAssuntos();
    this.sacListarTodos(this.pageNumber, this.filtros);
    this.montarDownload();
  },
  watch: {
    camposInput() {
      // this.sacListarTodos(this.pageNumber, this.filtros);
    },
    dadosTabela(val) {
      this.montarDownload(val);
      this.semDados = this.dadosTabela.length <= 0;
    },
    statusItem() {
      this.semStatus = this.statusItem.length <= 0;
    },
    assuntosItem() {
      this.semAssuntos = this.assuntosItem.length <= 0;
    },
    deep: true
  }
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
