<template>
  <div class="default-moderar moderar-sac">
    <div class="moderar-sac-container">
      <h1>{{ camposInput.titulo }} <small>Detalhes</small></h1>
      <div class="moderar-body">
        <div
          class="moderar-row"
          v-if="informacoesTabelaAtivada.usuarioPainel && informacoesTabelaAtivada.usuarioPainel.usuario"
        >
          <div class="moderar-panel">
            <h3>Informações do usuário:</h3>

            <div class="moderar-row">
              <div class="moderar-item">
                <label>Nome:</label><br />

                <p>{{ informacoesTabelaAtivada.usuarioPainel.usuario.nome }}</p>
              </div>
              <div class="moderar-item">
                <label>E-mail:</label><br />
                <p>{{ informacoesTabelaAtivada.usuarioPainel.usuario.email }}</p>
              </div>
              <div class="moderar-item">
                <label>CPF:</label><br />
                <p>{{ informacoesTabelaAtivada.usuarioPainel.usuario.cpf | formataCPF }}</p>
              </div>
              <div class="moderar-item">
                <label>Ativo:</label><br />
                <p>{{ informacoesTabelaAtivada.usuarioPainel.usuario.ativo ? "Sim" : "Não" }}</p>
              </div>
              <div class="moderar-item">
                <label>Data Atualização:</label><br />
                <p>{{ informacoesTabelaAtivada.usuarioPainel.usuario.dataAtualizacao | filterDataHora }}</p>
              </div>
              <div class="moderar-item">
                <label>Data Cadastro:</label><br />
                <p>{{ informacoesTabelaAtivada.usuarioPainel.usuario.dataCadastro | filterDataHora }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="moderar-row">
          <div class="moderar-panel">
            <h3>Informações da ocorrência:</h3>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Enviado em:</label><br />
                <p v-if="informacoesTabelaAtivada.sacOcorrencia">
                  {{ informacoesTabelaAtivada.sacOcorrencia.dataCadastro | filterDataHora }}
                </p>
              </div>
              <div class="moderar-item">
                <label>Assunto:</label><br />
                <p v-if="informacoesTabelaAtivada.sacOcorrencia.sacAssunto">
                  {{ informacoesTabelaAtivada.sacOcorrencia.sacAssunto.descricao }}
                </p>
              </div>
              <div class="moderar-item">
                <label>Status:</label><br />
                <p
                  v-if="informacoesTabelaAtivada.sacOcorrencia.sacStatus"
                  :class="`cupom-status ${informacoesTabelaAtivada.sacOcorrencia.sacStatus.descricao}`"
                >
                  {{ informacoesTabelaAtivada.sacOcorrencia.sacStatus.descricao }}
                </p>
              </div>
              <div class="moderar-item" v-if="informacoesTabelaAtivada.sacOcorrencia.sacStatus.id != 3">
                <label>Atualizar Status:</label><br />
                <inputValidate class="formulario-item formulario-comunicacao-tipo">
                  <multiselect
                    v-model="form.status"
                    :options="listaStatus"
                    label="descricao"
                    track-by="id"
                    :searchable="false"
                    :allow-empty="false"
                    placeholder="Selecione"
                    select-label=""
                    deselect-label=""
                    selected-label=""
                  />
                </inputValidate>
              </div>
            </div>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Nome:</label><br />
                <p v-if="informacoesTabelaAtivada.sacOcorrencia">{{ informacoesTabelaAtivada.sacOcorrencia.nome }}</p>
              </div>
              <div class="moderar-item">
                <label>E-mail:</label><br />
                <p v-if="informacoesTabelaAtivada.sacOcorrencia">{{ informacoesTabelaAtivada.sacOcorrencia.email }}</p>
              </div>
              <div class="moderar-item">
                <label>Celular:</label><br />
                <p v-if="informacoesTabelaAtivada.sacOcorrencia">{{ informacoesTabelaAtivada.sacOcorrencia.celular }}</p>
              </div>
              <div class="moderar-item">
                <label>Protocolo:</label><br />
                <p v-if="informacoesTabelaAtivada.sacOcorrencia">{{ informacoesTabelaAtivada.sacOcorrencia.protocolo }}</p>
              </div>
            </div>
            <div class="moderar-row">
              <div class="moderar-item">
                <label>Mensagem:</label><br />
                <p>{{ informacoesTabelaAtivada.mensagem }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="btns-formulario">
      <button class="btn btn-enviar btn-aprovar" @click="atualizacaoOcorrencia" v-if="informacoesTabelaAtivada.sacOcorrencia.sacStatus.id != 3">Salvar</button>
      <button class="btn btn-cancelar" @click="cancelarFormuario">Voltar</button>
    </div>
  </div>
</template>

<script>
import inputValidate from "@/components/formularios/InputValidate.vue";
import { atualizarStatusOcorrencia } from "@/api/sac";
import { _imagemCupom } from "@/api/_caminhosApi";
import { numberToReal } from "@/utils/money";
import { filterData, filterDataHora } from "@/utils/date";
import { formataCPF } from "@/utils/masks";
import Multiselect from "vue-multiselect";
export default {
  data() {
    return {
      form: {
        ordem: 0,
        status: null
      }
    };
  },
  components: {
    Multiselect,
    inputValidate
  },
  computed: {
    camposInput() {
      return this.$store.state.sac.componenteAtivado;
    },
    formularioTipo() {
      return this.$store.state.sac.formularioTipo;
    },
    tabela() {
      return this.$store.state.sac.tabela;
    },
    idTabelaAtivada() {
      return this.$store.state.sac.formularioEditarId;
    },
    informacoesTabelaAtivada() {
      return this.tabela.filter(item => item.id == this.idTabelaAtivada)[0];
    },
    listaStatus: {
      get() {
        return this.$store.state.sac.statuses;
      },
      set(item) {
        this.$store.commit("sac/SET_STATUS_ITENS", item);
      }
    }
  },
  filters: {
    filterData,
    filterDataHora,
    formataCPF
  },
  methods: {
    _imagemCupom,
    numberToReal,
    atualizarStatusOcorrencia,
    cancelarFormuario() {
      this.$store.commit("sac/SET_SAC_TELA", "Lista");
    },
    atualizacaoOcorrencia() {
      let data = {
        idStatus: this.form.status.id,
        email: this.informacoesTabelaAtivada.sacOcorrencia.email,
        protocolo: this.informacoesTabelaAtivada.sacOcorrencia.protocolo,
        idUsuarioPainel: JSON.parse(localStorage.idUser)
      };
      this.atualizarStatusOcorrencia(data);
      this.cancelarFormuario();
    }
  },
  mounted() {
    let value = null;
    this.listaStatus = this.listaStatus.filter(item => {
      return item.id !== value;
    });
  },
  watch: {
    listaStatus() {}
  }
};
</script>

<style lang="scss" scoped>
.moderar-sac {
  .btns-formulario {
    justify-content: space-evenly;
  }
  // .btn-enviar {
  //   &.btn-reprovar {
  //     background-color: red;
  //   }
  //   &.btn-aprovar {
  //     background-color: green;
  //   }
  // }
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
